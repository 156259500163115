<template>
    <div>
        <youtube :video-id="videoId" ref="youtube" :player-vars="playerVars" @playing="playing" width="95%" height="500px"></youtube>
    </div>
</template>

<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
 
Vue.use(VueYoutube)
export default {
  data() {
        return {
            videoId: 'fO3KR7zLwH8', 
            playerVars: {
                autoplay: 1
            }
        }
    },
    mounted() {
        //this.player.playVideo();
    },
    methods: {
        playing() {
            //console.log('playing');
        }
    },
    computed: {
        player() {
        return this.$refs.youtube.player
    }
  }
}
</script>